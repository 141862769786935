import { graphql } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout/Layout"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface AgbProps {
  data: {
    cms: {
      page: {
        title: string
        legalPagesFields: {
          content: string
        }
      }
    }
  }
}

export const Agb: React.FC<AgbProps> = ({ data }) => {
  return (
    <Layout bordertop="border-t-2">
      <div className="flex w-full flex-col items-center bg-homepageBackground pb-64 lg:w-11/12">
        <PageTitle agbTag={true} styleForLegalPages={true}>
          {data.cms.page.title}
        </PageTitle>
        <div
          style={{ maxWidth: 1302 }}
          className="rich-text-1 flex w-8/12 flex-col items-center bg-homepageBackground text-headings"
          dangerouslySetInnerHTML={{
            __html: data.cms.page.legalPagesFields.content,
          }}
        />
      </div>
    </Layout>
  )
}

export default Agb
export const query = graphql`
  {
    cms {
      page(id: "cG9zdDo2OTc=") {
        title
        legalPagesFields {
          content
        }
      }
    }
  }
`
